import React from "react"
import { Card, Elevation, Dialog,FormGroup, Alignment, Button, Tooltip, Classes, AnchorButton, Intent, Navbar, ButtonGroup, Position, Callout, Spinner } from "@blueprintjs/core"
import moment from 'moment';
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import pantallaInicio from '../../Assets/LOGO REGISTRADO PEQUEÑO.png'
import { CSVLink } from "react-csv";
import ReactDOM from 'react-dom';
import axios from 'axios';
import iconoFooter from '../../Assets/lc.png';
import i18next from "i18next";
import { Cargando } from "../Pantallas/Cargando"
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "./BootstrapTable";

import { 
    PDFViewer,
    Page,
    Text,
    View,
    Document,
    Image
} from '@react-pdf/renderer';

import styles from './stylesPrint';

class RegistrosRetributivos extends React.Component{
    state={
        datosCsv:[],
        regionSeleccionada:null,
        cargandoRR:false,
        cargandoImprimir:false,
        modalImprimir:false,
        cargandoHerramienta: false,
        modalHerramienta:false,
        fileName: '',
        DatosHerramienta:null,
        registros:[],
        datosImpresion:[],
        datosEmpresa:[],
        logotipo:null,
        cargandoCsv:false,
        personasFisicas:null,
        documentos:null
    }
    
    cargarDatosEmpresa=async()=>{         
            //DATOS JURIDICOS
            let datosEmpresa= await this.props.client
            .query({
                query: gql`
                {
                    personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
                }
                `,
                fetchPolicy: 'network-only'
    
            }).then(result=>{
                //si tenemos razon social.
                if(result.data.personasJuridicas.length>0){
                    return {
                        "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                    }
    
                }
                
            })
        
    
           return datosEmpresa
    
    }

    cargarPersonasFisicas= async () => {
            let personasFisicas=this.props.client
            .query({
                query: gql` 
                {personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
                `,
                fetchPolicy: 'network-only'
    
            }).then(result=>{ 
             return result.data.personasFisicas;
            })
            
            return personasFisicas;
           
    }

    cargarDocumentos= async () => {
        let documentos= await this.props.client
            .query({
                query: gql`
                {
                    documentosIdentificativos(
                    FILTROS: { ID_TIPO_DOCUMENTO: 1}
                    ) {
                    ID_DOCUMENTO_IDENTIFICATIVO,VALOR, ID_PERSONA
                    }
                }
                `,fetchPolicy:'network-only'
            })
            .then(result => {
                return result.data.documentosIdentificativos;
            });
        return documentos;
    }

    cargarDatos = async () => {
        let personasFisicas=await this.cargarPersonasFisicas();
        let datosEmpresa=await this.cargarDatosEmpresa();
        let documentos=await this.cargarDocumentos();
        
        this.setState({
            datosEmpresa:datosEmpresa,
            personasFisicas: personasFisicas,
            documentos: documentos
        })
    }

    cargarEmpleados=async()=>{
        await this.props.client
        .query({
            query: gql` 
                {empleados{ID_EMPLEADO,EMPLEADO{ID_PERSONA},RETRIBUCION,RETRIBUCION_VOLUNTARIA,PORCENTAJE_VOLUNTARIA,RETRIBUCION_VARIABLES,PORCENTAJE_VARIABLES,CODIGO_EMPLEADO}}
            `,
            fetchPolicy:'network-only'

            
        }).then(async result=>{ 
            let empleados=JSON.parse(JSON.stringify(result.data.empleados))
            for(let empleado of empleados){
                let personaSel=this.state.personasFisicas.find(persona=>{return persona.ID_PERSONA===empleado.EMPLEADO.ID_PERSONA})
                let docuSel=this.state.documentos.find(docu=>{return docu.ID_PERSONA===empleado.EMPLEADO.ID_PERSONA})
                empleado.nif=(docuSel)?docuSel.VALOR:null;
                empleado.nombre=`${personaSel.APELLIDO1}${personaSel.APELLIDO2?" "+personaSel.APELLIDO2:""},${personaSel.NOMBRE}`
                
            }   
            this.setState({ registros: empleados})
        })
    }
    obtenerHerramientaEmpresa=async()=>{
      //obtencion logo de la empresa
       await this.props.client
       .query({
           query: gql`
               {adjuntos(FILTROS:{NOMBRE:"HERRAMIENTA_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
           `,fetchPolicy:'network-only'             
       }).then(result=>{ 
           if(result.data.adjuntos.length>0){
  
              this.setState({DatosHerramienta:result.data.adjuntos[0]})
               
  
           }
       })
   
   }
    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await this.props.client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
    
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
    
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
    
             }
         })
     
     }

    componentDidMount(){
        this.setState({cargandoRR:true}, async()=>{
            await this.cargarDatos()
            await this.cargarEmpleados()
            await this.obtenerHerramientaEmpresa()
            await this.obtenerLogoEmpresa()
            this.setState({cargandoRR:false})
        })
    }

    cargarDatosGral=async ()=>{

        
        this.setState({ datosImpresion: this.state.registros })

    
    }

    generarInformeR=async()=>{
        this.setState({
            modalImprimir:true,
            cargandoImprimir:true
        }, async () => {
            await this.cargarDatosGral()
            this.setState({cargandoImprimir:false}, () => {
                ReactDOM.render(this.InformeRetribuciones(), document.getElementById('generar-pdf'))
            })
        })
    }

    InformeRetribuciones=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("registrosretributivos.recursoshumanos",{ns:"page"})}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("registrosretributivos.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                {this.state.datosImpresion?this.state.datosImpresion.map((registro, index)=>{
                    return (
                            <View key={index}>
                                {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("registrosretributivos.codigo",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'9%'}}>{i18next.t("registrosretributivos.nif",{ns:"page"})}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'21%'}}>{i18next.t("registrosretributivos.trabajador",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'12%'}}>{i18next.t("registrosretributivos.retribucionlegal",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'12%'}}>{i18next.t("registrosretributivos.retribucionvoluntaria",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'12%'}}>{i18next.t("registrosretributivos.porcvoluntaria",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'12%'}}>{i18next.t("registrosretributivos.retribucionvariables",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'12%'}}>{i18next.t("registrosretributivos.porcvariables",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                                )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{registro.CODIGO_EMPLEADO?registro.CODIGO_EMPLEADO:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'9%'}}>{registro.nif?registro.nif:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'21%'}}>{registro.nombre}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'12%'}}>{registro.RETRIBUCION?registro.RETRIBUCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'12%'}}>{registro.RETRIBUCION_VOLUNTARIA?registro.RETRIBUCION_VOLUNTARIA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'12%'}}>{registro.PORCENTAJE_VOLUNTARIA?registro.PORCENTAJE_VOLUNTARIA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'12%'}}>{registro.RETRIBUCION_VARIABLES?registro.RETRIBUCION_VARIABLES:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'12%'}}>{registro.PORCENTAJE_VARIABLES?registro.PORCENTAJE_VARIABLES:''}</Text>
                                </View>
                                
                            </View>
                        )
                    }
                ):<></>}
            </View>
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
    
    generarCsv =async () => {
        await this.cargarDatosCsv();
        this.Csv.link.click();
      }
      cargarDatosCsv=async ()=>{

        this.setState({ cargandoCsv: true })
        await this.cargarDatosGral();
        //cargar el select de personas.
      
            let registrosCsv=[]
            //let datosImpresion=[]
            for(let registro of this.state.datosImpresion){
                
                registrosCsv.push({"Codigo":registro.CODIGO_EMPLEADO,"NIF":registro.nif,"Trabajador":` ${registro.nombre}`, "Retribucion Legal":registro.RETRIBUCION,"Retribucion Voluntaria": registro.RETRIBUCION_VOLUNTARIA, "Porcentaje Voluntaria":registro.PORCENTAJE_VOLUNTARIA, "Retribucion Variables":registro.RETRIBUCION_VARIABLES, "Porcentaje Variables":registro.PORCENTAJE_VARIABLES})

            }


            registrosCsv.sort(function (a, b) {
                return ('' + a.Nombre).localeCompare(b.Nombre);
            })
            /*await datosImpresion.sort(function (a, b) {
                return ('' + a.nombre).localeCompare(b.nombre);
            })*/
            this.setState({ datosCsv: registrosCsv })

        

        this.setState({ cargandoCsv: false })

    }  

 render(){
        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("registrosretributivos.title",{ns:"page"})
    
    function valueFormatter(cell, row) {
    return (
    (cell)?cell:null
    );
    }

    const columns = [
    {
    dataField: 'CODIGO_EMPLEADO',
    text: i18next.t("registrosretributivos.codigo",{ns:"page"})
    },{
        dataField: 'nif',
        text: i18next.t("registrosretributivos.nif",{ns:"page"}),
        formatter: valueFormatter
        },{
        dataField: 'nombre',
        text: i18next.t("registrosretributivos.trabajador",{ns:"page"})
        },{
            dataField: 'RETRIBUCION',
            text: i18next.t("registrosretributivos.retribucionlegal",{ns:"page"}),
            formatter: valueFormatter
            },{
                dataField: 'RETRIBUCION_VOLUNTARIA',
                text: i18next.t("registrosretributivos.retribucionvoluntaria",{ns:"page"}),
                formatter: valueFormatter
                },{
                    dataField: 'PORCENTAJE_VOLUNTARIA',
                    text: i18next.t("registrosretributivos.porcvoluntaria",{ns:"page"}),
                    formatter: valueFormatter
                    },{
                        dataField: 'RETRIBUCION_VARIABLES',
                        text: i18next.t("registrosretributivos.retribucionvariables",{ns:"page"}),
                        formatter: valueFormatter
                        },{
                            dataField: 'PORCENTAJE_VARIABLES',
                            text: i18next.t("registrosretributivos.porcvariables",{ns:"page"}),
                            formatter: valueFormatter
                            }
    ];
    
    const cerrarModalImprimir=()=>{
        this.setState({modalImprimir: false})
    }

    const abrirModalHerramienta = () => {

        this.setState({
            modalHerramienta: !this.state.modalHerramienta
        })
    }

    const cerrarModalHerramienta = () => {
      this.setState({
          modalHerramienta: false,
        })
    }

    const editarHerramienta =(value) => {
            
        if (this.state.fileName!=='') {
          if(!this.refs.fileUploader.files[0]){

            alert(i18next.t("registrosretributivos.valid1",{ns:"page"}))
            return

          }
        }

        this.setState({
          cargandoHerramienta:true
        },async() => {
          //subida del archivo
          var formData = new FormData();
          formData.append("files",this.refs.fileUploader.files[0]);
          const api = axios.create({
              withCredentials: true
          });    
          
          //eliminamos el anterior
          await this.props.client 
          .query({
              query: gql`
                  {adjuntos(FILTROS:{NOMBRE:"HERRAMIENTA_EMPRESA",ID_TIPO_ADJUNTO:3,ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
              `,
              fetchPolicy:'network-only'
          }).then(async result=>{ 
              if(result.data.adjuntos.length>0){
                  await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{eliminarAdjunto(ID_ADJUNTO:${result.data.adjuntos[0].ID_ADJUNTO}){ID_ADJUNTO}}`)
              }
          })
          
          await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:3,NOMBRE:"HERRAMIENTA_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0,DESCRIPCION:"HERRAMIENTA_EMPRESA"}){ID_ADJUNTO}}`, formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
          }).then(async (response)=>{
            alert(i18next.t("registrosretributivos.uploadcorrect",{ns:"page"}))
            this.setState({
              fileName: '',
              cargandoHerramienta:false
            }, async ()=> {
              await this.obtenerHerramientaEmpresa()
            });
            cerrarModalHerramienta();
            
          }).catch(err=>{
            alert(i18next.t("error",{ns:"global"}))
            this.setState({
              fileName: '',
              cargandoHerramienta:false
            });
            cerrarModalHerramienta();
          })
        });

        

        
    }

    const onFileChange = event => {
    
      // Update the state
      this.setState({ fileName: event.target.files[0].name });
    
    };
    const abrirSubida = () => {
        this.refs.fileUploader.click()
    }
        
     return(
        <>  {this.state.cargandoRR?
            <Cargando/>
        :
        <>
             <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="generar-pdf">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModalHerramienta}
                    title={i18next.t("registrosretributivos.herramienta",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    className="dialog-ancho pixelAlrededorGrande"
                    isOpen={this.state.modalHerramienta}
                    style={styles.dialog}
                >
                  <>
                  {this.state.cargandoHerramienta &&
                      <Spinner/>
                  }
                    <FormGroup
                    >
                      <input type="file" id="file" ref="fileUploader" onChange={onFileChange}  style={{display: "none"}}/>
                      {this.state.DatosHerramienta && Object.keys(this.state.DatosHerramienta).length>0 &&
                        <p>{i18next.t("registrosretributivos.valid4",{ns:"page"})} <button onClick={abrirSubida}>{i18next.t("here",{ns:"global"})}</button>
                          <span style={{marginLeft: 10 }}>{this.state.fileName}</span>
                        </p>
                      }
                      {this.state.DatosHerramienta && Object.keys(this.state.DatosHerramienta).length>0?
                        <p>{i18next.t("registrosretributivos.valid2",{ns:"page"})} <a href={process.env.REACT_APP_DOMINIO+""+this.state.DatosHerramienta.DATOS.replace(".","")} target="_blank" rel="noopener noreferrer">{i18next.t("here",{ns:"global"})}</a></p>
                      :
                        <p>{i18next.t("registrosretributivos.valid3",{ns:"page"})} <button onClick={abrirSubida}>{i18next.t("here",{ns:"global"})}</button><span style={{marginLeft: 10 }}>{this.state.fileName}</span></p>
                      }
                    </FormGroup>
                    <div className={Classes.DIALOG_FOOTER}>
                      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                          <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                              <Button onClick={cerrarModalHerramienta}>{i18next.t("close",{ns:"global"})}</Button>
                          </Tooltip>
                          <Tooltip content={i18next.t("edit",{ns:"global"})+" "+i18next.t("registrosretributivos.herramienta",{ns:"page"})} position="top">
                              <AnchorButton
                                  intent={Intent.PRIMARY}
                                  onClick={editarHerramienta}

                              >
                                  {i18next.t("edit",{ns:"global"})+" "+i18next.t("registrosretributivos.herramienta",{ns:"page"})}
                              </AnchorButton>
                          </Tooltip>
                      </div>
                    </div>
                  </>
            </Dialog>
                
            <div id="contenedor-registros-retributivos">
                <div className="pixelAlrededor">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("registrosretributivos.title",{ns:"page"})}</Callout>
                            <Navbar>
                                <Navbar.Group>
                                    <ButtonGroup aling={Alignment.LEFT}>
                                        <Tooltip content={i18next.t("print",{ns:"global"})} position={Position.RIGHT}>
                                            <Button key="botonImprimir" className="bp3-minimal" icon="print" text={i18next.t("print",{ns:"global"})} id="boton-imprimir" onClick={() => this.generarInformeR()} />
                                        </Tooltip>
                                        <CSVLink ref={(r) => { this.Csv = r; }} data={this.state.datosCsv} separator={";"} enclosingCharacter={``} filename={"RegistrosRetributivos.csv"}></CSVLink>
                                        <Tooltip content={i18next.t("exportar",{ns:"global"})} position={Position.RIGHT}>
                                            <Button
                                                className="bp3-minimal"
                                                icon="document"
                                                text={i18next.t("exportar",{ns:"global"})}
                                                onClick={() => this.generarCsv()}
                                            >
                                            </Button>
                                        </Tooltip>

                                        <Button className="bp3-minimal" icon="document-open" text={i18next.t("registrosretributivos.herramienta",{ns:"page"})}  onClick={abrirModalHerramienta}></Button>
                                    </ButtonGroup>
                                </Navbar.Group>
                            </Navbar>
                            <>
                                <BootstrapTable
                                keyField='ID_EMPLEADO'
                                data={ this.state.registros }
                                columns={ columns }
                                pagination={ paginationFactory(const_table.options) }
                                headerClasses="header-class"
                                rowClasses="row-class"
                                hover
                                condensed
                                noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                                />
                                
                            </>
                    </Card>

                </div>

            </div>
        </>
        }
      </>     
     )
 }

}

export default withApollo(RegistrosRetributivos)